var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "index-container"
  }, [_c('keep-alive', {
    attrs: {
      "include": _vm.keepAlives
    }
  }, [_c('router-view')], 1), _vm.$store.state.showTabbar ? _c('van-tabbar', {
    ref: "tabbar",
    model: {
      value: _vm.active,
      callback: function callback($$v) {
        _vm.active = $$v;
      },
      expression: "active"
    }
  }, [_c('van-tabbar-item', {
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function fn() {
        return [_c('van-icon', {
          attrs: {
            "slot": "left-icon",
            "class-prefix": "mhj-icon",
            "name": "shouye"
          },
          slot: "left-icon"
        })];
      },
      proxy: true
    }], null, false, 2180155110)
  }, [_vm._v(" 首页 ")]), _c('van-tabbar-item', {
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function fn() {
        return [_c('van-icon', {
          attrs: {
            "slot": "left-icon",
            "class-prefix": "mhj-icon",
            "name": "wode"
          },
          slot: "left-icon"
        })];
      },
      proxy: true
    }], null, false, 3111336546)
  }, [_vm._v(" 我的 ")])], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }