<template>
  <div class="index-container">
    <keep-alive :include="keepAlives">
      <router-view />
    </keep-alive>
    <van-tabbar v-if="$store.state.showTabbar" ref="tabbar" v-model="active">
      <van-tabbar-item>
        <template #icon>
          <van-icon slot="left-icon" class-prefix="mhj-icon" name="shouye" />
        </template>
        首页
      </van-tabbar-item>
      <van-tabbar-item>
        <template #icon>
          <van-icon slot="left-icon" class-prefix="mhj-icon" name="wode" />
        </template>
        我的
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data() {
    return {
      active: 0,
      routePaths: [
        '/home',
        '/mine'
      ]
    }
  },
  computed: {
    keepAlives() {
      return this.$store.state.keepAlives
    }
  },
  watch: {
    active(index) {
      // 防止刷新后，纠正下标时报错的问题
      if (this.$route.path !== this.routePaths[index]) { this.$router.replace(this.routePaths[index]) }
    },
    $route(newRoute) {
      this.checkActiveIndex(newRoute)
    }
  },
  created() {
    // 清理keepAlive缓存
    this.$store.commit('clearKeepAlives')
    this.checkActiveIndex(this.$route)
    this.$store.commit('setKeepAlive', 'Home')
    this.$store.commit('setKeepAlive', 'Mine')
  },
  methods: {
    checkActiveIndex(route) {
      const index = this.routePaths.indexOf(route.path)
      // 隐藏tabbar
      if (index === -1) {
        this.$store.commit('showTabbar', false)
      } else {
        // 由于路由keep-alive，防止刷新后，tabbar选中的下标与展示的界面不符合
        this.active = this.routePaths.indexOf(this.$route.path)
        // 显示tabbar
        this.$store.commit('showTabbar', true)
      }
    }
  }
}
</script>

<style scoped>

</style>
